import {gql} from '@apollo/client'

export const CONTACT_MUTATION = gql`
    mutation WebContacts(
        $name: String!
        $email: String!
        $message: String!
      ) {
        webContacts(
          name: $name
          email: $email
          message: $message
        )
      }
`
