import React, { useState } from "react";
import styles from "./contacts.module.scss";
import Layout from "../../components/layout/layout";
import { Link } from "gatsby";
import {Alert, Col, Row} from "react-bootstrap";
import { Mutation } from 'react-apollo'
import { CONTACT_MUTATION } from "../../apollo/mutations/contact";


const Contacts = ({ location }) => {
  const [state, setState] = useState({ name: "", email: "", message: "" });
  const [formErrors, setFormErrors] = useState({
    nameError: '',
    emailError: '',
    messageError: ''
  })

  const handleInputChange = event => {
    setState({...state, [event.target.name]: event.target.value})
  }

  const validate = () => {
    let nameError = '';
    let emailError = '';
    let messageError = '';

    if (state.name.trim().length === 0) {
      nameError = `This field can't be empty`;
    }
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (state.email.trim().length === 0) {
      emailError = `This field can't be empty`;
    } else if (!state.email.trim().match(emailRegex)){
      emailError = 'Please type in correct email address'
    }

    if (state.message.trim().length === 0) {
      messageError = `This field can't be empty`;
    }

    setFormErrors({nameError: nameError, emailError: emailError, messageError: messageError})
    return !(nameError || emailError || messageError);
  }

  return (
    <Layout location={location}>
      <div className={styles.contacts}>
        <h1>Contact Us</h1>
        <Row className={styles.contacts__container}>
          <Col xs={12} md={6} className={styles.contacts__info}>
            <p>The CELLR app is the destination for users to curate every aspect of their wine life.</p>
            <p>
              Wine has become such an integral part of our social, food, and travel life that it
              deserves to have a tool to reference all the wines we are lucky enough to experience.
            </p>
            <p>
              Whether users want to inventory the holdings of their cellar, keep track of their
              wish list when they travel and dine, trade with other cellar owners, or just receive
              the occasional special that is aligned with their tastes, CELLR will be that destination…
            </p>
            <p>
              CELLR is currently available as an invite only app on Apple Test Flight. We are
              constantly adding new Users and would love to have you as a part of the CELLR community!
              To get added to our Beta group send us an message with your info or visit our
              <Link to="/beta/">Become Beta User</Link> page.
            </p>
          </Col>
          <Col xs={12} md={6}>
            <Mutation mutation={CONTACT_MUTATION}>
              {(sendContacts, {loading, error, data}) => (
                <React.Fragment>

                  {data && (<Alert className={styles.alert}>{data?.webContacts}</Alert>)}
                  {error && (<Alert className={styles.alert}>{error?.message + ' Try Again!'}</Alert>)}

                  <form onSubmit={async (event) => {
                                    event.preventDefault()
                                    const isValid = validate()
                                    if (isValid) {
                                      sendContacts({
                                        variables: {
                                          name: state.name,
                                          email: state.email,
                                          message: state.message
                                        }
                                      })
                                    }
                                  }}
                        autoComplete="off"
                        className={styles.contacts__form}
                  >
                    <div>
                      <input type="text" name="name" placeholder="Name" value={state.name}
                             className={formErrors.nameError ? 'invalid-input' : ''}
                             onChange={handleInputChange}
                      />
                      { formErrors.nameError ? <span>{formErrors.nameError}</span> : null }
                    </div>
                    <div>
                      <input type="text" name="email" placeholder="Email" value={state.email} onChange={handleInputChange}/>
                      { formErrors.emailError ? <span>{formErrors.emailError}</span> : null }
                    </div>
                    <div>
                      <textarea
                          name="message"
                          placeholder="Message"
                          value={state.message}
                          onChange={handleInputChange}
                      />
                      { formErrors.messageError ? <span>{formErrors.messageError}</span> : null }
                    </div>
                    <button type="submit" disabled={loading}>Send</button>
                  </form>
                </React.Fragment>
              )}
            </Mutation>

          </Col>
        </Row>
      </div>
    </Layout>
  )
}

export default Contacts;
